import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";
import openSocket from 'socket.io-client';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(userName: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}Token`, {
        userName,
        password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem('MYTOKEN');
        localStorage.removeItem('USERNAME');
        localStorage.removeItem('USERTYPE');
        localStorage.removeItem('USERID');
        localStorage.removeItem('USERCODE');
        localStorage.removeItem('MYSRC');
        localStorage.removeItem('BILLINGID');
        localStorage.removeItem('menus');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }

  requestGetRoleList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'ApplicationRoles';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  requestGetUserList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Admin';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetBranchList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Branches';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetStateList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'States';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetLaboratoryList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Laboratories';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetCategoryList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'ItemCategories1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  requestGetCategoryList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'ItemCategories';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  requestGetHealthList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'HealthTips';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  requestGetProductList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Tests';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetCustomerList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Customers';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetBookingList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Bookings';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetBookingList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Bookings1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  todayCollections(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'TodayCollections';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetCollectorList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'SampleCollectors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  
  deleteCategory(categoryId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ItemCategories/' + categoryId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteCustomer(categoryId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Customers/' + categoryId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  todayCollect(categoryId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'TodayCollections/' + categoryId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getProduct(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Tests/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getState(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'States/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getPrescription(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Prescription/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getCategory(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'ItemCategories/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getHealth(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'HealthTips/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getCustomer(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Customers/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getCustomerFamily(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'MyFamilies/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getBooking(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Bookings/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addCategory(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ItemCategories';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addHealth(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'HealthTips';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addProduct(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Tests';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addBooking(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Bookings';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addCustomer(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Customers';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateProduct(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Tests/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateAllotment(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'BookingAllotment/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateAllotment1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'BookingAllotment';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateCustomer(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Customers/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateCategory(categoryId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ItemCategories/' + categoryId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateHealth(categoryId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'HealthTips/' + categoryId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  private getEventMessage(event: HttpEvent<any>, formData) {

    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
		break;
      case HttpEventType.Response:
        return this.apiResponse(event);
		break;
      default:
        return `File "${formData.get('profile').name}" surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

  itemcategoryUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'ItemCategoryImage/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  healthtipUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'HealthTipImage/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  deleteCategorySrc(userId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ItemCategories1/' + userId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteProduct(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Tests/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteHealth(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'HealthTips/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteHealthSrc(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'HealthTipImage/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addUser(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Admin';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addBranch(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Branches';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addRole(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ApplicationRoles';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addLaboratory(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Laboratories';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateUser(userId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Admin/' + userId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateLaboratory(userId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Laboratories/' + userId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getUser(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Admin/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getBranch(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Branches/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getLaboratory(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Laboratories/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getBranchuser(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'BranchUsers/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteUser(userId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Admin/' + userId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteLaboratory(userId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Laboratories/' + userId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteBranch(userId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Branches/' + userId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateUsername(userId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ChangeUsername/' + userId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateBranch(userId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Branches/' + userId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  changepassword(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'PasswordChange/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  
}
