import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
  // Admin Modules
  {
    path: "/admin/dashboard/main",
    title: "Dashboard",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["SuperAdministrator"],
    submenu: [],
  }, 
  {
    path: "/admin/setting",
    title: "Settings",
    moduleName: "admin",
    iconType: "material-icons-two-tone",
    icon: "settings",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "/admin/branch_franchise",
    title: "Branch & Franchise",
    moduleName: "admin",
    iconType: "material-icons-two-tone",
    icon: "location_city",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "/admin/laboratory",
    title: "Laboratories",
    moduleName: "admin",
    iconType: "material-icons-two-tone",
    icon: "bloodtype",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "/admin/user",
    title: "User Access",
    moduleName: "admin",
    iconType: "material-icons-two-tone",
    icon: "person",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "/admin/test",
    title: "Test",
    moduleName: "admin",
    iconType: "material-icons-two-tone",
    icon: "vaccines",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "/admin/customer",
    title: "Customers",
    moduleName: "admin",
    iconType: "material-icons-two-tone",
    icon: "manage_accounts",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "/admin/booking",
    title: "Test Bookings",
    moduleName: "admin",
    iconType: "material-icons-two-tone",
    icon: "menu_book",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "/admin/allotment",
    title: "Booking Allotment",
    moduleName: "admin",
    iconType: "material-icons-two-tone",
    icon: "event",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "/admin/collection",
    title: "Sample Collection",
    moduleName: "admin",
    iconType: "material-icons-two-tone",
    icon: "playlist_add_circle",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "/admin/report",
    title: "General Reports",
    moduleName: "admin",
    iconType: "material-icons-two-tone",
    icon: "flag",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "/admin/healthtip",
    title: "Health Tips",
    moduleName: "admin",
    iconType: "material-icons-two-tone",
    icon: "health_and_safety",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [],
  },
];

